import React, { useEffect, useRef, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const AmenitiesCarousel = () => {
  const carouselRef = useRef(null);


  const options = {
    loop: false, // Disable infinite loop since we are manually handling direction
    rewind: true,
    margin: 10,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 5000,
    // autoplayHoverPause: true,
    // autoplaySpeed: 1000,
    // navSpeed: 1000,
    smartSpeed: 2000, // Smooth transition between slides
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 3
      }
    }
  };

  return (
    <section className="section shadow-sm lazyload" id="amenities">
      <span className="section-link" />
      <h2 className="head text-capitalize">
        Amenities Of Lodha Vikhroli Evergreen
      </h2>
      <OwlCarousel
        id="ami-3"
        className="ami-3 owl-carousel owl-theme"
        {...options}
        ref={carouselRef}
      >
        <div className="item-wrp">
          <div className="ami_sec">
            <img
              src="./img/aminities/Amenities_3/library_01.webp"
              loading="lazy"
              className="ami_sec lazyload"
            />
            <p> CAFE CUM LIBRARY </p>
          </div>
          <div className="ami_sec">
            <img
              src="./img/aminities/Amenities_5/GRANDCLUBHOUSE.webp"
              loading="lazy"
              className="ami_sec lazyload"
            />
            <p> CLUB HOUSE </p>
          </div>
          <div className="ami_sec">
            <img
              src="./img/aminities/Amenities/creche1.webp"
              loading="lazy"
              className="ami_sec lazyload"
            />
            <p>CRECHE</p>
          </div>
        </div>
        <div className="item-wrp">
          <div className="ami_sec">
            <img
              src="./img/aminities/Amenities_3/garden_01.webp"
              loading="lazy"
              className="ami_sec lazyload"
            />
            <p>GARDEN</p>
          </div>
          <div className="ami_sec">
            <img
              src="./img/aminities/Amenities_3/gym_01.webp"
              loading="lazy"
              className="ami_sec lazyload"
            />
            <p>GYM</p>
          </div>
          <div className="ami_sec">
            <img
              src="./img/aminities/Amenities_4/indoorgamesarea_1.webp"
              loading="lazy"
              className="ami_sec lazyload"
            />
            <p>INDOOR GAME ROOM</p>
          </div>
        </div>
        <div className="item-wrp">
          <div className="ami_sec">
            <img
              src="./img/aminities/Amenities/indoorgames1.webp"
              loading="lazy"
              className="ami_sec lazyload"
            />
            <p> INDOOR GAMES </p>
          </div>
          <div className="ami_sec">
            <img
              src="./img/aminities/Amenities_4/joggingtrack_1.webp"
              loading="lazy"
              className="ami_sec lazyload"
            />
            <p>JOGGING TRACK</p>
          </div>
          <div className="ami_sec">
            <img
              src="./img/aminities/Amenities/kidsplayarea1.webp"
              loading="lazy"
              className="ami_sec lazyload"
            />
            <p> Kids’ Play Area </p>
          </div>
        </div>
        <div className="item-wrp">
          <div className="ami_sec">
            <img
              src="./img/aminities/Amenities/partyhall1.webp"
              loading="lazy"
              className="ami_sec lazyload"
            />
            <p>PARTY HALL</p>
          </div>
          <div className="ami_sec">
            <img
              src="./img/aminities/Amenities/sports1.webp"
              loading="lazy"
              className="ami_sec lazyload"
            />
            <p> SPORTS TURF</p>
          </div>
          <div className="ami_sec">
            <img
              src="./img/aminities/Amenities/squash1.webp"
              loading="lazy"
              className="ami_sec lazyload"
            />
            <p> SQUASH COURT </p>
          </div>
        </div>
      </OwlCarousel>
    </section>
  );
};

export default AmenitiesCarousel;
