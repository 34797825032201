import React from 'react';
import './Thankyou.css'; // Assuming you'll add the external CSS

export const Thankyou = () => {
  return (
    <>
      <section className="thank-you-section">
        <div className="thank-you-content">
          <header className="site-header" id="header">
            <h1 className="site-header__title">THANK YOU!</h1>
          </header>
          <div className="main-content">
            <i className="fa fa-check main-content__checkmark" id="checkmark" />
            <p className="main-content__body">
              Thanks for Inquiring, One of our executives will call you shortly
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
